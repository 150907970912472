import React from "react"
import Container from "../../components/container"
import Head from "../../components/head"
import {getNames} from "country-list"
import NodeGarden from "../../components/new/nodeGarden/nodeGarden"
import Grid from "../../components/new/grid/grid"
import Layout from "../../components/new/layout/layout"
import { useStaticQuery, graphql } from "gatsby"
import Paragraph from "../../components/new/paragraph/paragraph"
import SubHeader from "../../components/new/subHeader/subHeader"
import HubspotContactFormPartner from "../../components/hubspotContactFormPartner"


export default function Sellers () {
	const data = useStaticQuery(graphql`
		query {
			allContentfulCustomer(filter: {featured:{eq: true}} limit: 4) {
				edges {
					node {
						logo
						title
						featured
						url
					}
				}
			}
		}
	`)
	const countries = getNames()
    return (
		<Layout navbar='darkerblue'>
			<Head title="Transform Your Marketplace Operations Now" description="Ensure the future of your marketplace by booking a demo of the Onport Platform now."/>
			<Container style={{classes: "container-fluid bg-contact-lg py-5" }}>
				<Container style={{classes: "container position-relative" }}>
					<div className="row mt-5 pt-5 align-items-start">
						<div className="col-12 col-md-6 py-5">
                        	<Paragraph text='Onport Unite' style='lightBlue xxl'/>
							<SubHeader title='Connect with a curated network from +200 marketplaces using Onport to manage backend operations' style='light'/>
                        	<Paragraph text='Start selling more ' style='light w-75 mt-5 xxl'/>
                        	<Paragraph text='Access new sales channels that share the same target audience and fit into your business ethos and strategy.' style='light w-75 mt-3'/>
                        	<Paragraph text='Increase brand awareness' style='light w-75 mt-5 xxl'/>
                        	<Paragraph text='Make your brand known to a wider audience by expanding your reach to support your growth.' style='light w-75 mt-3'/>
                        	<Paragraph text='Ensure a seamless onboarding ' style='light w-75 mt-5 xxl'/>
                        	<Paragraph text='Streamline your onboarding process with a configuration that makes marketplace operations simple and efficient. ' style='light w-75 mt-3'/>
                        	<Paragraph text='Set up product data easily' style='light w-75 mt-5 xxl'/>
                        	<Paragraph text='Import your product catalog using our ecommerce integrations or other options (API, CSV, etc), to choose which products to sell in each one of your selected marketplaces.' style='light w-75 mt-3'/>
						</div>
						<div className="col-12 col-md-6 bg-lol2 pt-4">
								<h2 className="text-white pl-4" style={{fontWeight:'bold'}}>Take the first step today</h2>
								<HubspotContactFormPartner id="dcd3bebd-ef67-4d3c-b12a-109f9420cc68"/>
						</div>
					</div>
					<Grid style='grid-4 mt-5 py-4'>
								{data.allContentfulCustomer.edges.map((edge) => {
									if (edge.node.featured) {
										return(
											<a href={edge.node.url} target="_blank">
												<img src={edge.node.logo} alt={edge.node.title} style={{opacity: '0.6', width:'160px', filter:'invert(100%) saturate(0)', margin: '1rem 0'}}/>
											</a>
										)
									}
								})}
					</Grid>
				</Container>	
			</Container>
		</Layout>
    )
}